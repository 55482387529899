import React from 'react'
import { Link } from 'react-router-dom'

function BannerSection() {
    return (
        <>
            {/* BANNER SECTION */}
            <div className="bg-outer-wrapper position-relative float-left w-100">

                <section className="float-left w-100 banner-con postion-relative main-box">
                    <img alt="vector" className="triangle-shape img-fluid position-absolute" src="/assets/images/triangle-vector1.png" />
                    <img alt="vector" className="half-circle-shape img-fluid position-absolute" src="/assets/images/green-circle-shape.png" />
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className="banner-content-con">
                                    <img className="img-fluid" alt="vector" src="/assets/images/dots-vector.png" />
                                    <h1 className>ENGUNEL İlə <br />
                                        Uğurlar Sizi <br />
                                        Gözləyir !</h1>
                                    <p className="font-weight-light">Düzgün seçim etmək <br />
                                        gələcəyinizi qorumaq deməkdir.</p>
                                    <div className="light-green-btn d-inline-block">
                                        <Link to="/elaqe" className="d-inline-block">Bizimlə Əlaqə</Link>
                                    </div>
                                    <div className="dark-btn d-inline-block">
                                        <Link to="/haqqimizda" className="d-inline-block">Daha Ətraflı</Link>
                                    </div>
                                    {/* banner content con */}
                                </div>
                                {/* col */}
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="banner-img-con">
                                    <img className src="/assets/images/home-banner-img.png" alt="image" />
                                    {/* banner image con */}
                                </div>
                                {/* col */}
                            </div>
                            {/* row */}
                        </div>
                        {/* container */}
                    </div>
                    {/* banner con */}
                </section>
            </div>
        </>
    )
}

export default BannerSection