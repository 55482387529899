import React, { useContext } from 'react'
import { CoursesContext } from '../Context/GlobalState';
import MiniCourse from './MiniCourse';

function SimilarCourse({ parent }) {
    const { courses } = useContext(CoursesContext);
    return (
        <div className="news-white-box">
            <h5 className>Oxşar dərslər</h5>
            {courses.filter(course => course.cat_parent_id == parent).sort(() => 0.5 - Math.random()).slice(0, 3).map(course => (
                <MiniCourse id={course.id} />
            ))
            }
            <div className="view-all-link-con text-center">
                <a href="blog.html">Hamısı</a>
                {/* view all link con*/}
            </div>
            {/* news white box */}
        </div>
    )
}

export default SimilarCourse