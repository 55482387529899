import axios from 'axios';
import React, { useContext, useState } from 'react'
import { CoursesContext } from '../Context/GlobalState';

function RequestCourse({course_id}) {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const { courses } = useContext(CoursesContext);
    const course = courses.find(course => course.id === course_id);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://engunel.com/api/request.php', {
                name: name,
                phone: phone,
                message: message,
                course_id: course.id,
                teacher_id: course.tId
            });

            if (response.status === 200) {
                setStatus('Məlumatınız uğurla göndərildi!');
            } else {
                setStatus('Xəta baş verdi, xahiş edirik yenidən cəhd edin.');
            }
        } catch (error) {
            setStatus('Xəta baş verdi, xahiş edirik yenidən cəhd edin.');
        }
    };
    return (
        <form className="main-form" method="post" id="request" onSubmit={handleSubmit}>
            <ul className="list-unstyled p-0 float-left w-100 mb-0">
                <li>
                    <input type="text" placeholder="Adınız" name="name" id="name" value={name}
                        onChange={(e) => setName(e.target.value)} />
                </li>
                <li>
                    <input type="tel" placeholder="Telefon" name="phone" id="phone" value={phone}
                        onChange={(e) => setPhone(e.target.value)} />
                </li>
                <li>
                    <textarea placeholder="Sual və rəyiniz" rows={6} name="msg" defaultValue={""} value={message}
                        onChange={(e) => setMessage(e.target.value)} />
                </li>
            </ul>
            <div className="light-green-btn">
                <button type="submit" id="submit">Göndər</button>
            </div>
        </form>
    )
}

export default RequestCourse