import './App.css';
import { BrowserRouter,Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Courses';
import CourseDetail from './pages/CourseDetail';
import ComingSoon from './pages/ComingSoon';

function App() {
  return (
   <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/muellimlerimiz" element={<ComingSoon/>} />
      <Route path="/suallariniz" element={<ComingSoon/>} />
      <Route path="/reyleriniz" element={<ComingSoon/>} />
      <Route path="/bloq" element={<ComingSoon/>} />
      <Route path="/haqqimizda" element={<ComingSoon/>} />
      <Route path="/elaqe" element={<ComingSoon/>} />
      <Route path="/dersler" element={<Courses/>} />
      <Route path="/dersler/:id" element={<CourseDetail/>} />
    </Routes>
   </BrowserRouter>
  );
}

export default App;
