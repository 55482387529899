import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Header() {
    const location = useLocation();
    const menuItems = [
        { name: 'Ana Səhifə', path: '/' },
        { name: 'Müəllimlərimiz', path: '/muellimlerimiz' },
        { name: 'Dərslər', path: '/dersler' },
        {
            name: 'Məlumat',
            path: '#',
            dropdown: [
                { name: 'Müəllimlərimiz', path: '/muellimlerimiz' },
                { name: 'Suallarınız', path: '/suallariniz' },
                { name: 'Rəyləriniz', path: '/reyleriniz' }
            ]
        },
        { name: 'Bloq', path: '/bloq' }
    ];
    return (
        <div>
            {/* HEADER SECTION */}
            <header className="w-100 flaot-left header-con main-box position-relative">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link to="/" className="navbar-brand" >
                            <figure className="mb-0">
                                <img src="/assets/images/engunel-logo.png" alt="Engunel Logo" />
                            </figure>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                            <span className="navbar-toggler-icon" />
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                {menuItems.map((item, index) => (
                                    <li className={`nav-item${item.dropdown ? ' dropdown' : ''}`} key={index}>
                                        {item.dropdown ? (
                                            <>
                                                <a className="nav-link dropdown-toggle p-0" href={item.path} id={`navbarDropdown${index}`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{
                                                color: location.pathname == '/' ? '#fafafa' : '#545454'
                                            }}>{item.name}</a>
                                                <div className="dropdown-menu" aria-labelledby={`navbarDropdown${index}`}>
                                                    {item.dropdown.map((subItem, subIndex) => (
                                                        <Link to={subItem.path} className="dropdown-item" key={subIndex}>{subItem.name}</Link>
                                                    ))}
                                                </div>
                                            </>
                                        ) : (
                                            <Link to={item.path} className="nav-link p-0" style={{
                                                color: location.pathname == '/' ? '#fafafa' : '#545454'
                                            }}>{item.name}</Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <div className="header-contact">
                                <ul className="list-unstyled mb-0">
                                    <li><Link to="/elaqe" className="contact-btn d-inline-block">Əlaqə</Link></li>
                                    {/* list unstyled */}
                                </ul>
                                {/* header contact */}
                            </div>
                            {/* navbar collapse */}
                        </div>
                    </nav>
                    {/* container */}
                </div>
                {/* header-con */}
            </header>
        </div>
    )
}

export default Header