import React, { createContext, useState, useEffect } from 'react';

export const CoursesContext = createContext();

export const CoursesProvider = ({ children }) => {
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCoursesAndCategories = async () => {
            try {
                // Kursları yükləmək
                const coursesResponse = await fetch('https://engunel.com/api/courses.php');
                const coursesData = await coursesResponse.json();

                // Kateqoriyaları yükləmək
                const categoriesResponse = await fetch('https://engunel.com/api/categories.php');
                const categoriesData = await categoriesResponse.json();

                setCourses(coursesData);
                setCategories(categoriesData);
                setLoading(false);
            } catch (error) {
                console.error('Məlumatları yükləyərkən xəta baş verdi:', error);
                setLoading(false);
            }
        };

        fetchCoursesAndCategories();
    }, []);

    return (
        <CoursesContext.Provider value={{ courses, categories, loading }}>
            {children}
        </CoursesContext.Provider>
    );
};