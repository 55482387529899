import React, { useContext } from 'react'
import { CoursesContext } from '../Context/GlobalState';
import { Link } from 'react-router-dom';

function Course({ id, pop=0 }) {
    const { courses } = useContext(CoursesContext);
    return (
        <>
        {
            courses.filter(course => course.id == id && (pop == 0 ? course.popular == 0 || course.popular == 1 : course.popular ==1)).map(course => (
                <div key={course.id} className="courses-box">
                    <img className="img-fluid" src={`/assets/images/course/${course.course_image}`} alt={course.title} title={course.title} />
                    <div className="course-bottom-con">
                        <div className="icon d-flex justify-content-between align-items-center">
                            <div className="lesson">
                                <i className="fa-solid fa-book" />
                                <span className="text-size-13 text mr-0">Onlayn</span>
                            </div>
                            <div className="date">
                                <i className="fa-solid fa-eye" />
                                <span className="text-size-13 text">{course.view_count}</span>
                            </div>
                            <div className="price font-weight-600">
                                ₼{course.price}
                            </div>
                        </div>
                        <h5 className="font-weight-600">{course.name}</h5>
                        <p>{course.tName}</p>
                        <div className="light-green-btn d-inline-block">
                            <Link to={`/dersler/${course.id}`} className="d-inline-block">Qoşul</Link>
                        </div>
                        {/* course bottom con */}
                    </div>
                    {/* courses box */}
                </div>
            ))
        }
        </>
    )
}

export default Course