import React, { useContext, useEffect } from 'react'
import MainLayout from '../layout/MainLayout'
import SubBannerSection from '../layout/SubBannerSection'
import { CoursesContext } from '../Context/GlobalState';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SimilarCourse from '../components/SimilarCourse';
import PopCourseRight from '../components/PopCourseRight';
import axios from 'axios';
import RequestCourse from '../components/RequestCourse';

function CourseDetail() {
    const { courses } = useContext(CoursesContext);
    const { id } = useParams();
    const course = courses.find(course => course.id === id);
    const handleImageError = (e) => {
        e.target.src = '/assets/images/profile/teacher.png';
    };

    useEffect(() => {
        const fetchCourse = async () => {
                try {
                    await axios.get(`https://engunel.com/api/course_review.php?course_id=${course.id}`);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
        };

        fetchCourse();
    }, [id]);

    return (
        <MainLayout>
            {course &&
                <>
                    <SubBannerSection title={course.parent_name} />
                    <section className="float-left w-100 position-relative our-blogs-new-con padding-top116 padding-bottom116 main-box">
                        <div className="container wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <div className="course-info d-flex">
                                        <div className="teacher-info d-flex">
                                            <div className="profile-photo">
                                                <img
                                                    src={`/assets/images/profile/${course.tProfile}`}
                                                    alt={course.title}
                                                    title={course.title}
                                                    onError={handleImageError}
                                                />
                                            </div>
                                            <div className="profile-name">
                                                <span>Müəllimə:</span>
                                                <Link>{course.tName}</Link>
                                            </div>
                                        </div>
                                        <div className="course-price d-flex">
                                            <span className="price">{course.price} ₼</span>
                                            <div className='test'>Ödənişsiz <br /> sınaq Dərsi</div>
                                        </div>
                                    </div>
                                    <div className="blog-detail-wrapper">
                                        <img className="w-100" alt="image" src={`/assets/images/course/${course.course_image}`} />
                                        <div className="blog-detail-content">
                                            <h4>{course.title} </h4>
                                            <i className="fa-solid fa-eye mr-2" />
                                            <span className=" text">{course.view_count}</span>
                                            <p>
                                                {course.description}
                                            </p>
                                        </div>
                                        {/* <div className="share-now-con">
                                    <span className="d-inline-block">Share Now:</span>
                                    <ul className="list-unstyled p-0 m-0 mb-0 d-inline-block social-icon">
                                        <li className="d-inline-block"><a href="https://www.facebook.com/login/"><i className="fa-brands fa-facebook-f ml-0" /></a></li>
                                        <li className="d-inline-block"><a href="https://twitter.com/i/flow/login"><i className="fa-brands fa-x-twitter" /></a></li>
                                        <li className="d-inline-block"><a href="https://www.instagram.com/" className="mr-0"><i className="fa-brands fa-instagram mr-0" /></a></li>
                                    </ul>
                                </div> */}
                                        <div className="leave-us-rply-con">
                                            <div className="heading-title-con">
                                                <h4 className="font-weight-600">Sınaq dərsi əldə et</h4>
                                                {/* heading title con */}
                                            </div>
                                            <RequestCourse course_id={course.id} />
                                            {/* leave us rply con */}
                                        </div>
                                        {/* blog detail wrapper */}
                                    </div>
                                    {/* col */}
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="our-blogs-inner-wrap2">

                                        <SimilarCourse parent={course.cat_parent_id} />
                                        <PopCourseRight parent={course.cat_parent_id} pop={1} />
                                    </div>
                                    {/* col */}
                                </div>
                                {/* row */}
                            </div>
                            {/* container */}
                        </div>
                        {/* our blogs new con */}
                    </section>
                </>
            }
        </MainLayout>

    )
}

export default CourseDetail