import React from 'react'
import Header from '../layout/Header'

function ComingSoon() {
    return (
        <>
            <Header />
            <div className="error-outer-wrapper position-relative float-left w-100 coming-soon-outer-wrapper d-flex flex-column coming-outer-sec">
                <img alt="vector" className="triangle-shape img-fluid position-absolute" src="assets/images/triangle-vector1.png" />
                <img alt="vector" className="half-circle-shape img-fluid position-absolute" src="assets/images/green-circle-shape.png" />
                <img alt="vector" className="triangle-shape-prple img-fluid position-absolute" src="assets/images/triangle-vector2.png" />
                <img alt="vector" className="half-circle-shape-prple img-fluid position-absolute" src="assets/images/prple-circle-shape.png" />
                <section className="float-left w-100 position-relative error-banner-con main-box coming-soon-banner d-flex flex-column justify-content-center">
                    <div className="container wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className="error-inner-content">
                                    <p className="green-text">Veb saytımızda sizin üçün çalışırıq!</p>
                                    <h1 className="special-font100">Tezliklə
                                        sizinlə</h1>
                                    <span className="d-block error-code">Yeniliklərdən xəbərdar olmaq üçün bizi izləyin</span>
                                    <form action="javascript:;">
                                        <div className="form-group mb-0 position-relative">
                                            <input type="text" className="form_style" placeholder="Buraya E-mail daxil edin" name="email" />
                                            <button><i className="fa-solid fa-envelope" /></button>
                                        </div>
                                    </form>
                                    {/* error inner content */}
                                </div>
                                {/* col */}
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="error-banner-img position-relative">
                                    <img className="img-fluid error-img" alt="image" src="assets/images/coming-soon-img.png" />
                                    <img className="img-fluid position-absolute img2" alt="vector" src="assets/images/dots-vector.png" />
                                    {/* error banner img */}
                                </div>
                                {/* col */}
                            </div>
                            {/* row */}
                        </div>
                        {/* container */}
                    </div>
                    {/* error banner con */}
                </section>
                {/*  */}
            </div>
        </>

    )
}

export default ComingSoon