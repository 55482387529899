import React from 'react'

function SubBannerSection({title='Engunel'}) {
    return (
        <div className="sub-outer-wrapper position-relative float-left w-100">
            <section className="float-left w-100 sub-banner-con postion-relative main-box text-center">
                <img alt="vector" className="triangle-shape img-fluid position-absolute" src="/assets/images/triangle-vector1.png" />
                <img alt="vector" className="half-circle-shape img-fluid position-absolute" src="/assets/images/green-circle-shape.png" />
                <img alt="vector" className="triangle-shape-prple img-fluid position-absolute" src="/assets/images/triangle-vector2.png" />
                <img alt="vector" className="half-circle-shape-prple img-fluid position-absolute" src="/assets/images/prple-circle-shape.png" />
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-12 mr-auto ml-auto">
                            <div className="sub-banner-inner-con padding-top80">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img className="img-fluid d-inline-block left-img" alt="vector" src="/assets/images/dots-vector.png" />
                                    <h1 className="d-inline-block mb-0">{title}</h1>
                                    <img className="img-fluid d-inline-block right-img" alt="vector" src="/assets/images/dots-vector.png" />
                                    {/* d flex */}
                                </div>
                                {/* sub banner inner con */}
                            </div>
                            {/* col */}
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </div>
                {/* banner con */}
            </section>
        </div>

    )
}

export default SubBannerSection