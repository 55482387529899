import React from 'react'
import MainLayout from '../layout/MainLayout'
import BannerSection from '../layout/BannerSection'
import PopCourseSection from '../layout/PopCourseSection'
import ResultSection from '../layout/ResultSection'

function Home() {
  return (
    <MainLayout>
        <BannerSection />
        <PopCourseSection />
        <ResultSection />
    </MainLayout>
  )
}

export default Home