import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            {/* FOOTER SECTION  */}
            <div className="float-left w-100 position-relative footer-con main-box padding-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6 col-sm-6">
                            <div className="footer-inner-content">
                                <Link to="/"><img className="img-fluid" alt="icon" src="/assets/images/engunel-logo.png" /></Link>
                                <p>Gələcəyiniz üçün yolunuzu düzgün seçin, doğru addımlar atın. Təhsiliniz sizin gələcəyinizin təməlidir.</p>
                                <p className="mb-0">
                                    Hörmətli valideynlər, övladlarınızı peşəkar kollektivimizə əmanət edə bilərsiniz.</p>
                                {/* footer inner content */}
                            </div>
                            {/* col */}
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="footer-inner-links-con">
                                <h6 className="text-white span-heading d-block">Cəld keçidlər</h6>
                                <ul className="p-0 m-0 list-unstyled">
                                    <li><Link to="/haqqimizda"> Haqqımızda</Link></li>
                                    <li><Link to="/dersler">Dərslər</Link></li>
                                    <li><Link to="/muellimlerimiz">Müəllimlərimiz</Link></li>
                                    <li className="mb-0"><Link to="/bloq">Bloq</Link></li>
                                    {/* list unstyled */}
                                </ul>
                                {/*  footer inner links con*/}
                            </div>
                            {/* col */}
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="footer-inner-links-con">
                                <h6 className="text-white span-heading d-block">Dəstək</h6>
                                <ul className="p-0 m-0 list-unstyled">
                                    <li><Link to="/elaqe">Əlaqə</Link></li>
                                    <li><Link to="/muellimlerimiz"> Müəllimlərimiz</Link></li>
                                    <li><Link to="/suallariniz">Suallarınız</Link></li>
                                    <li><Link to="/reyleriniz">Rəyləriniz</Link></li>
                                    <li className="mb-0"><Link to="/dersler">Dərslər</Link></li>
                                    {/* list unstyled */}
                                </ul>
                                {/*  footer inner links con*/}
                            </div>
                            {/* col */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer-inner-links-con var4">
                                <h6 className="text-white span-heading d-block">Əlaqə məlumatları</h6>
                                <ul className="list-unstyled p-0">
                                    <li className="position-relative"><i className="fa-solid fa-envelope" /> <Link to="mailto:info@engunel.com">info@engunel.com</Link></li>
                                    <li className="position-relative mb-0"><i className="fa-solid fa-phone" /> <Link to="tel:+994505348024">+994
                                        (050) 534 80 24</Link></li>
                                </ul>
                                <ul className="list-unstyled p-0 m-0 d-flex align-items-center social-icon mb-0">
                                    <li><Link to="https://www.facebook.com/login/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f ml-0" /></Link></li>
                                    <li><Link to="https://twitter.com/i/flow/login" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-x-twitter" /></Link></li>
                                    <li><Link to="https://www.instagram.com/en.gunel/" target="_blank" rel="noopener noreferrer" className="mr-0"><i className="fa-brands fa-instagram mr-0" /></Link></li>
                                </ul>
                                {/* footer inner links con */}
                            </div>
                            {/* col */}
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </div>
                <hr />
                <div className="container">
                    <div className="float-left w-100 copyright-con text-center main-box">
                        <p className="mb-0">© 2024 ENGUNEL. All Rights Reserved</p>
                        {/* copyright con */}
                    </div>
                    {/* container */}
                </div>
                {/* footer-con */}
            </div>
        </>
    )
}

export default Footer