import React, { useContext } from 'react'
import { CoursesContext } from '../Context/GlobalState';
import Course from '../components/Course';

function PopCourseSection() {
    const { courses, loading } = useContext(CoursesContext);
    return (
        <>
            {/* POPULAR COURSES SECTION  */}
            <section className="float-left w-100 position-relative main-box popular-courses-con padding-top">
                <img alt="vector" className="blue-shape img-fluid position-absolute" src="/assets/images/vector-shape2.png" />
                <img alt="vector" className="blue-shape2 img-fluid position-absolute" src="/assets/images/vector-shape1.png" />
                <img alt="vector" className="triangle-shape img-fluid position-absolute" src="/assets/images/triangle-vector2.png" />
                <img alt="vector" className="half-circle-shape img-fluid position-absolute" src="/assets/images/prple-circle-shape.png" />
                <div className="container wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="heading-title-con text-center">
                        <h2 className="font-weight-600 large-font">Populyar dərslər</h2>
                        <p>Tələbələrimizin ən çox seçdiyi və yüksək nəticələr əldə etdiyi dərslər. Bu kurslar biliklərinizi genişləndirmək və məqsədlərinizə çatmaq üçün ideal seçimdir.</p>
                        {/* heading title con */}
                    </div>
                    <div className="row">
                        {courses.map(course => (
                        <div key={course.id} className="col-lg-4 col-md-6">
                            <Course id={course.id} pop={1} />
                            {/* col */}
                        </div>
                        ))}
                        {/* row */}
                    </div>
                    {/* container */}
                </div>
                {/* popular courses con */}
            </section>
        </>
    )
}

export default PopCourseSection