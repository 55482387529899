import React, { useContext, useState } from 'react'
import SubBannerSection from '../layout/SubBannerSection'
import MainLayout from '../layout/MainLayout'
import { CoursesContext } from '../Context/GlobalState';
import Course from '../components/Course';

function Courses() {
    const { courses } = useContext(CoursesContext);
    const tabs = [...new Map(courses.map(course =>
        [course['parent_name'], course])).values()];
    const [selectTab, setSelectTab] = useState(0);
    console.log(selectTab);
    
    const handleTabClick = (catParentId) => {
        setSelectTab(catParentId);
    };

    return (
        <MainLayout>
            <SubBannerSection title="Kurslar" />

            <section className="float-left w-100 position-relative main-box courses-tabs-con padding-top116 padding-bottom116 main-box">
                <div className="container wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                    {/* tabs  */}
                    <div className="tabs-box tabs-options">
                        <ul className="nav nav-tabs justify-content-center align-items-center">
                            {tabs.map((course, index) => (
                                <li key={course.cat_id}>
                                    <a data-toggle="tab" href={`#cat${course.cat_parent_id}`}  onClick={() => handleTabClick(course.cat_parent_id)} className={`${(selectTab == course.cat_parent_id) || (selectTab == 0 && index == 0) ? 'active show' : ''}`}>
                                        {course.parent_name}
                                    </a>
                                </li>
                            ))}
                        </ul>

                        {/* tabs  */}
                        <div className="tab-content">
                            {tabs.map((pcat, catIndex) => (
                                <div key={pcat.cat_parent_id} id={`#cat${pcat.cat_parent_id}`} className={`tab-pane fade ${(selectTab == pcat.cat_parent_id) || (selectTab == 0  && catIndex == 0)? 'active show' : ''}`}>
                                    <div className='row'>
                                        {courses.filter(course => course.cat_parent_id == pcat.cat_parent_id).map(course => (
                                            <div key={course.id} className="col-lg-4 col-md-6">
                                                <Course id={course.id} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* container */}
                </div>
                {/* courses tabs con */}
            </section>

        </MainLayout>
    )
}

export default Courses