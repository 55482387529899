import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { CoursesContext } from '../Context/GlobalState';

function MiniCourse({ id, pop = 0 }) {
    const { courses } = useContext(CoursesContext);
    return (
        <>
            {
                courses.filter(course => course.id == id && (pop == 0 ? course.popular == 0 || course.popular == 1 : course.popular == 1)).map(course => (
                    <div key={course.id} className="recent-posts-sub d-flex align-items-center">
                        <img className="w-50" alt="image" src={`/assets/images/course/${course.course_image}`} />
                        <div className="recent-posts-content">
                            <span className="text-size-13 text"> {course.tName}</span>
                            <p><Link to={`/dersler/${course.id}`}>{course.name}</Link></p>
                            {/* recent posts content */}
                        </div>
                        {/* recent posts sub */}
                    </div>
                ))
            }
        </>
    )
}
export default MiniCourse