import React from 'react'
import { Link } from 'react-router-dom'

function ResultSection() {
    return (
        <>
            <section className="float-left w-100 position-relative main-box advance-ur-career-con padding-bottom">
                <img alt="vector" className="triangle-shape img-fluid position-absolute" src="/assets/images/triangle-vector1.png" />
                <img alt="vector" className="half-circle-shape img-fluid position-absolute" src="/assets/images/green-circle-shape.png" />
                <div className="container wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <img className="text-center img-fluid" src="/assets/images/advance-career-img.png" alt="image" />
                            {/* col */}
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="services-content-con">
                                <span className="d-block special-text">NƏTİCƏ</span>
                                <h2 className="font-weight-600">TƏLƏBƏLƏRİN ƏLDƏ ETDİYİ NAİLİYYƏTLƏR</h2>
                                <p className="font-weight-light">Tələbələrimizin qazandığı uğurlar, ENGUNEL-də tədrisin keyfiyyətini və effektivliyini əks etdirir. </p>
                                <p className="font-weight-light mb-0">Biz, hər bir tələbənin məqsədlərinə çatmasını təmin etmək üçün onları bilik, bacarıq və özünəinamla təchiz edirik. Öyrənmə prosesində hər bir addımınızda yanınızdayıq. </p>
                                <div className="light-green-btn d-inline-block">
                                    <Link to="/haqqimizda" className="d-inline-block">Ətraflı</Link>
                                </div>
                                {/* services content con */}
                            </div>
                            {/* col */}
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </div>
                {/* advance your career */}
            </section>
        </>
    )
}

export default ResultSection